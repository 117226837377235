'use client'
import * as React from 'react'
import { HeroProps } from './types'

const Hero = ({ content, image }: HeroProps) => {
    return (
        <div className="relative flex h-full min-h-[320px] w-full items-center z-0 justify-center">
            <div className="bg-black [&>img]:opacity-[0.6] absolute inset-0 h-full [&>img]:h-full [&>img]:w-full [&>img]:object-cover">
                {image}
            </div>
            <div className={'relative flex flex-col gap-y-4 text-center text-white [&>p]:max-w-[480px]'}>{content}</div>
        </div>
    )
}

export default Hero
