'use client'

import * as React from 'react'
import { ModelBlockComponentProps } from './types'
import classNames from 'classnames'
import Button from '../Button'

const ModelBlock = ({ as, href, title, image, onClick, type, paddingBig = false }: ModelBlockComponentProps) => {
    return (
        // @ts-ignore
        <Button as={as} href={href} type={type} onClick={onClick} className="h-fit hover:cursor-pointer">
            <span
                className={classNames(
                    `hover:border-t-brand-red group flex flex-col items-center justify-center border-t-2 border-t-black bg-gray-100 py-4 text-center`,
                    { 'px-16': paddingBig }
                )}
            >
                <p className="group-hover:text-brand-red pb-4 text-sm font-medium">{title}</p>
                {image && <figure>{image}</figure>}
            </span>
        </Button>
    )
}
export default ModelBlock
