'use client'

import * as React from 'react'
import { createElement } from 'react'
import { ButtonProps, ButtonRenderComponent } from './types'
import LoadingIcon from 'ui/icons/LoadingIcon'

const classNames = require('classnames')

const Button = <T extends ButtonRenderComponent>({
    as,
    className,
    variant,
    disabled,
    iconLeft,
    loading,
    onClick,
    children,
    ...props
}: ButtonProps<T>) => {
    const btn = `${
        loading || disabled ? '' : 'btn hover:btn-hover'
    } leading-4 md:leading-6 hover:ease-in-out hover:text-white py-2 px-4 uppercase font-bold inline-flex relative box-border justify-center outline-0 select-none items-center text-base text-center decoration-0 transform-none overflow-hidden h-10 disabled:bg-brand-grey400 disabled:text-brand-grey100 disabled:border-0 font-bold text-base`
    const primary = `${btn} bg-brand-red text-white`
    const outline = `${btn} text-brand-grey800 bg-white border border-brand-grey800`
    const dark = `${btn} text-white bg-brand-grey800 border border-brand-grey800`
    const disabledProps =
        loading || disabled ? '!bg-brand-grey400 !text-brand-grey100 !border-0 cursor-not-allowed' : 'cursor-pointer'
    const typedProps = props as ButtonProps<T>

    typedProps.className = classNames(
        `${disabledProps} disabled:pointer-events-none disabled:opacity-50`,
        {
            [outline]: variant === 'outline',
            [primary]: variant === 'primary',
            [dark]: variant === 'dark',
        },
        className,
    )

    typedProps.onClick = (e: any) => {
        if (!loading && !disabled) {
            if (onClick) {
                onClick(e)
            }
        } else {
            e.preventDefault()
        }
    }

    return createElement(
        as || 'button',
        props,
        <>
            {iconLeft && <span className="border-brand-grey800 block border-r pr-2">{iconLeft}</span>}
            {children}
            {loading && (
                <span className="ml-3">
                    <LoadingIcon />
                </span>
            )}
        </>,
    )
}

export default Button
